import Swiper from "swiper";
import SwiperCore, { Navigation, Autoplay } from "swiper/core";
SwiperCore.use([Navigation, Autoplay]);

const sliders = () => {

  const swiperAbout = new Swiper(".swiper-about-main", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,

    runCallbacksOnInit: true,
    navigation: {
      nextEl: ".swiper-about-main__next",
 
    },

    on: {
      slideChange: function () {
        var currentSlide = this.realIndex + 1;
        document.querySelector(
          ".counter__current"
        ).innerHTML = `0${currentSlide}`;
      },
      beforeInit: function () {
        let numOfSlides =
          this.wrapperEl.querySelectorAll(".swiper-slide").length;
        document.querySelector(".counter__total").innerHTML = `0${numOfSlides}`;
      },
    },
    // });
  });

  if (document.querySelector(".swiper-about-page")) {
    const swiperAboutPage = new Swiper(".swiper-about-page", {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,

      runCallbacksOnInit: true,
      navigation: {
        nextEl: ".swiper-about-page__next",
      },

      on: {
        slideChange: function () {
          var currentSlide = this.realIndex + 1;
          document.querySelector(
            ".counter__current-about"
          ).innerHTML = `0${currentSlide}`;
        },
        beforeInit: function () {
          let numOfSlides =
            document.querySelector('.swiper-about-page').querySelectorAll(".swiper-slide").length;

          document.querySelector(
            ".counter__total-about"
          ).innerHTML = `0${numOfSlides}`;
        },
      },
    });
  }
  if (document.querySelector(".swiper-about-project")) {
    const swiperAboutPage = new Swiper(".swiper-about-project", {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,

      runCallbacksOnInit: true,
      navigation: {
        nextEl: ".swiper-about-project__next",
      },

      on: {
        slideChange: function () {
          var currentSlide = this.realIndex + 1;
          document.querySelector(
            ".counter__current-project"
          ).innerHTML = `0${currentSlide}`;
        },
        beforeInit: function () {
          let numOfSlides =
            document.querySelector('.swiper-about-project').querySelectorAll(".swiper-slide").length;

          document.querySelector(
            ".counter__total-project"
          ).innerHTML = `0${numOfSlides}`;
        },
      },
    });
  }
  if (document.querySelector(".team-wrapper")) {
    const swiperTeam = new Swiper(".swiper-team", {
      slidesPerView: 4,
      spaceBetween: 12,
      loop: true,

      runCallbacksOnInit: true,
      navigation: {
        nextEl: ".swiper-team__next",
        prevEl: ".swiper-team__prev",
      },

      on: {
        slideChange: function () {
          var currentSlide = this.realIndex + 1;
          document.querySelector(
            ".counter__current-team"
          ).innerHTML = `0${currentSlide}`;
        },
        beforeInit: function () {
          let numOfSlides =
          document.querySelector('.swiper-team').querySelectorAll(".swiper-slide").length;
  
          document.querySelector(
            ".counter__total-team"
          ).innerHTML = `0${numOfSlides}`;
        },
      },
      breakpoints: {
        1200: {
          slidesPerView: 5,
        },
        992: {
          slidesPerView: 4,
          centeredSlides: false,
        },
        768: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        576: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        375: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        320: {
          slidesPerView: 1,
          centeredSlides: false,
        },
      },
    });
  }
  if (document.querySelector(".swiper-project-partners")) {
    const swiperTeam = new Swiper(".swiper-project-partners", {
      slidesPerView: 4,
      spaceBetween: 12,
      // loop: true,
      autoplay: {
        delay: 5000,
      },
 
      breakpoints: {
        1200: {
          slidesPerView: 5,
        },
        992: {
          slidesPerView: 4,
          centeredSlides: false,
        },
        768: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        576: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        375: {
          slidesPerView: 1,
          centeredSlides: false,
        },
        320: {
          slidesPerView: 1,
          centeredSlides: false,
        },
      },
    });
  }
  if (document.querySelector(".reports-wrapper")) {
    const swiperTeam = new Swiper(".swiper-reports", {
      slidesPerView: 4,
      spaceBetween: 12,


      runCallbacksOnInit: true,
      navigation: {
        nextEl: ".swiper-reports__next",
        prevEl: ".swiper-reports__prev",
      },

 
      breakpoints: {
        1200: {
          slidesPerView: 5,
        },
        992: {
          slidesPerView: 4,
          centeredSlides: false,
        },
        768: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        576: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        375: {
          slidesPerView: 1,
          centeredSlides: false,
        },
        320: {
          slidesPerView: 1,
          centeredSlides: false,
        },
      },
    });
  }
  if (document.querySelector(".gratitude-wrapper")) {
    const swiperTeam = new Swiper(".swiper-gratitude", {
      slidesPerView: 4,
      spaceBetween: 30,

      runCallbacksOnInit: true,
      navigation: {
        nextEl: ".swiper-gratitude__next",
        prevEl: ".swiper-gratitude__prev",
      },


      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
        // 992: {
        //   slidesPerView: 4,

        // },
        768: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 2,
        },
        375: {
          slidesPerView: 1,
        },
        320: {
          slidesPerView: 1,
        },
      },
    });
  }
};
function countSlides(t) {
  let numOfSlides = t.wrapperEl.querySelectorAll(".swiper-slide").length;
  t.el.nextSibling.querySelector(
    ".counter__total"
  ).innerHTML = `0${numOfSlides}`;
}
function changeCount(t) {
  var currentSlide = t.realIndex + 1;
  t.el.nextSibling.querySelector(
    ".counter__current"
  ).innerHTML = `0${currentSlide}`;
}
export default sliders;
